import {useState, useEffect, useRef} from 'react';
import { socket } from './Socket'
import './App.css';
import { AiOutlineClose, AiOutlineMenu} from 'react-icons/ai'

// function chat_display(chat) {
//   return (
//     <div>
//       <p><b>{chat.user}:</b>{chat.text}</p>
//       {chat.user === "assistant" && <p><button onClick={this.showSources}>Show Sources</button></p>}

//     </div>
//   );
// }

function SourceDisplay(props) {
  return (
    <div>
      <h3 className="text-xl font-bold"><a class="underline" href={props.link}>{props.title}</a></h3>
      <p>...{props.text}{props.text.length < 400 ? "" : "..."}</p>
    </div>
  );
}

function ChatDisplay(props) {

  const [showSources, setShowSources] = useState(false);

  return (
    <div>
    {props.user === "user" ? 
        //Add red box around user chat
        // <div className="m-auto w-4/5 text-right p-7 bg-red-400 ">
        <div className="m-auto text-left p-7 bg-red-400 border-8 border-red-800 border-solid flex" ref={props.refProp}>
          <div className="flex-1">
            {props.text.split("\n").map((text) => <p className="ml-16 mr-4 text-right">{text}</p>)}
          </div>
          <div className="ml-auto flex flex-col items-center">
            <div class="ml-auto w-16 h-16 bg-red-600 rounded-full flex items-center justify-center">
              <p className="text-4xl">🧑</p>
            </div>
            <p className="text-center mt-2"><b>User</b></p>
          </div>
        </div>
    
    : 
        <div>
          <div className="m-auto text-left bg-red-200 p-7 border-8 border-red-800 border-solid md:flex" ref={props.refProp}>
            <div className="mr-auto flex flex-col items-center">
              <div class="mr-auto w-16 h-16 bg-red-900 rounded-full"><img src="/chattpi_720.png" alt="ChatTPI"/></div>
            </div>
            <div className="flex-1">
              {props.text.split("\n").map((text) => <p className="mr-16 ml-4 text-left">{text}</p>)}
            </div>
            <p><button className="my-2 text-center px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-lg" onClick={() => setShowSources(!showSources)}>{!showSources ? "Show Sources" : "Hide Sources"}</button></p>
          </div>
          {showSources && <div className='m-auto text-left bg-blue-200 p-7 border-8 border-blue-800 border-solid'>
            <h2 className="text-xl font-bold">Sources:</h2>
            {props.sources && props.sources.map((source) => <SourceDisplay title={source.title} text={source.text} link={source.link} />)}
            </div>
            }
        </div>
    }
    </div>
  );
}

function App() {
  const chatRef = useRef([]);
  const lastRef = useRef(null);
  const [prompt, setPrompt] = useState('');
  const [chat, setChat] = useState([]);
  const [nav, setNav] = useState(false);

  useEffect(() => {
    (chat.length !== 0 && chat[chat.length-1].user === 'user') && socket.emit('submit_chat', {"chat": chat});
  }, [chat]);

  useEffect(() => {
    chatRef.current = chat;
  }, [chat]);

  useEffect(() => {
    (chat.length !== 0 && chat[chat.length-1].user === 'user') && lastRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [chat]);

  useEffect(() => {
    function onHyDE(value) {
        setChat([...chatRef.current, {user: "assistant", text: value, sources: null}])
    }
    function onSources(value) {
      // Set the sources for the last chat message
      setChat([...chatRef.current.slice(0, -1), {user: "assistant", text: chatRef.current[chatRef.current.length-1].text, sources: value}])
    }

    function onChunk(value) {
      console.log(value)
        if (chatRef.current !== [] && chatRef.current[chatRef.current.length-1].text !== "Retrieving sources...") {
          setChat([...chatRef.current.slice(0, -1), {user: "assistant", text: chatRef.current[chatRef.current.length-1].text + value, sources: chatRef.current[chatRef.current.length-1].sources}])
        }
        else {
          setChat([...chatRef.current.slice(0, -1), {user: "assistant", text: value, sources: chatRef.current[chatRef.current.length-1].sources}])
        }
    }

    function onConnect() {
      console.log('connected');
    }
    function onError(error) {
      console.log(error);
      alert(error);
    }
    socket.on('HyDE', onHyDE)
    socket.on('sources', onSources)
    socket.on('output', onChunk)
    socket.on('connect', onConnect)
    socket.on('error', onError)
    return () => {
      socket.off("HyDE", onHyDE);
      socket.off("sources", onSources);
      socket.off("output", onChunk);
      socket.off("connect", onConnect);
    };
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitChat = () => {
    setChat(chat => chat.concat({user: "user", text: prompt, sources: null}));
    setPrompt('');
  }

  const clearChat = () => {
    setChat([]);
  }

	const handleNav = () => {
		setNav(!nav);
	}

  return (
    <div className="min-h-screen flex flex-col bg-zinc-800">
      <div className='m-auto pt-5 pb-3 w-9/12'>
        <div className="text-red-600">  
          <p><img className="align-baseline inline w-24 h-24" src="/chattpi_720.png" alt="ChatTPI"/> by the  <a href="https://techpolicyinstitute.org">Technology Policy Institute</a></p>
        </div>
        <p className="text-white pt-1">Chat with TPI's archive of sources</p>
      </div>
      {/* <div onClick={handleNav}>
        {nav ? <AiOutlineClose size={40} className='fixed right-[5rem] top-10 z-50 text-white hover:text-red-600 hover:cursor-pointer'/> 
        : <AiOutlineMenu size={40} className='fixed right-[5rem] top-10 z-50 text-white hover:text-red-600 hover:cursor-pointer duration-300'/>}
      </div>
      <div className={nav ? 'fixed right-0 top-0 w-[25rem] h-full border-l-4 border-red-600 bg-zinc-800 z-40 ease-in-out duration-[250ms]' 
      : 'fixed top-0 w-[25rem] h-full bg-zinc-800 z-40 ease-in duration-[400ms] right-[-100%]'}>
        <ul className='pt-28 text-white text-2xl'>
          <li className="p-5 border-b-4 border-red-600 hover:cursor-pointer hover:text-red-600 duration-300 active:text-red-600" id = "link" href="#scatter">TPI Website</li>
          <li className="p-5 border-b-4 border-red-600 hover:cursor-pointer hover:text-red-600 duration-300 active:text-red-600" id = "link" href="#scatter">About ChatTPI</li>
          <li className="p-5 border-b-4 border-red-600 hover:cursor-pointer hover:text-red-600 duration-300 active:text-red-600" id = "link" href="#top">Disclaimer</li>
          <li className="p-5 border-b-4 border-red-600 hover:cursor-pointer hover:text-red-600 duration-300 active:text-red-600" id = "link" href="/sources">Contact Us</li>
        </ul>
      </div> */}
      <div className='m-auto w-9/12 flex-grow overflow-y-scroll pb-40'>
        { chat.length !== 0 && chat.map((chat, i) => <ChatDisplay user={chat.user} text={chat.text} sources={chat.sources} refProp={lastRef} />)}
        {/* { chat.length === 0 && <br />} */}
        { chat.length === 0 && <p className='text-white'>Disclaimer: ChatTPI is an AI-based chatbot we developed to help users learn from TPI's 15-year collection of research, events, podcasts, and more. However, please note that because ChatTPI is based on generative AI, the responses it provides may not necessarily reflect the views, opinions, or positions of our organization.</p>}
        { chat.length === 0 && <p className='text-white'>We strongly recommend that users check the sources ChatTPI provides and also independently verify information.</p>}
        { chat.length === 0 && <p className='text-white'>If you have any concerns or feedback regarding the chatbot's responses, please contact Nathaniel Lovin at nlovin@techpolicyinstitute.org.</p>}
      </div>
      
      <div className="fixed bottom-0 w-full text-center items-center justify-center">
        <div className="relative flex flex-col w-3/5 mx-auto">
        <textarea className="p-2.5 flex-grow text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" rows="2" cols="100" value={prompt} onChange={(e) => setPrompt(e.target.value)} />
        <button className="absolute mt-2 mr-1 md:mr-2 text-center self-end px-4 py-2 bg-red-500 hover:bg-red-600 text-white rounded-lg" onClick={submitChat}>Submit</button>
        </div>
        <button className="my-2 text-center px-4 py-2 bg-red-500 hover:bg-red-600 text-white rounded-lg" onClick={clearChat}>Clear</button>
      </div>
    </div>
  );

}

export default App;
